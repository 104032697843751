import styled from 'styled-components';
import { Checkbox, Button, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input as CommonInput } from '@app/components/common/inputs/Input/Input';
import { InputPassword as CommonInputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
import loginBackground from '@app/assets/images/login-bg.webp';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';

export const resetContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 10px;
  color: #a5a5a5;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Poppins' !important;
  margin-bottom: 10px;
`;
export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url(${loginBackground});
  background-size: cover;
  position: relative;
  opacity: 0.4;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  & > div {
    max-width: 30rem;
    padding: 20px 50px !important;
  }
`;

export const FormWrapper = styled.div`
  padding: 20x 50px;
  width: 3.75rem;
  overflow: auto;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);

  @media only screen and (${media.xs}) {
    padding: 2.5rem 1.25rem;
    width: 20.75rem;
    max-height: calc(100vh - 3rem);
  }

  @media only screen and (${media.md}) {
    padding: 2.5rem;
    width: 31.75rem;
    max-height: calc(100vh - 3rem);
  }
`;

export const FormTitle = styled.div`
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20px;
  /* width: 10.53em; */

  margin: auto;
  align-items: center;
  text-align: center;

  .title {
    margin-top: 20px;
  }

  @media only screen and (${media.xs}) {
    margin-bottom: 0.625rem;
    font-size: ${FONT_SIZE.lg};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.5625rem;
  }

  @media only screen and (${media.md}) {
    margin-bottom: 0.875rem;
    font-size: ${FONT_SIZE.xxl};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.9375rem;
  }

  @media only screen and (${media.xl}) {
    margin-bottom: 0.9375rem;
    font-size: ${FONT_SIZE.xxxl};
    font-weight: ${FONT_WEIGHT.extraBold};
    line-height: 2.125rem;
  }
`;

export const FormCheckbox = styled(Checkbox)`
  display: flex;
  padding-left: 0.125rem;

  & .ant-checkbox-inner {
    border-radius: 3px;
    transform: scale(1.375);
  }

  & .ant-checkbox-input {
    transform: scale(1.375);
  }
`;

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`;

export const FormInput = styled(CommonInput)`
  &.ant-input {
    border-radius: none !important;
    background: transparent;
    color: var(--text-main-color);
    background: transparent;
    border: none;
    border-bottom: 1px solid #e4e9eb !important;
    padding-bottom: 20px !important;
    padding: 10px 0px !important;
    font-family: 'Poppins' !important;

    &:is(:hover, :focus, :active) {
      border-color: white;
      border-bottom: 1px solid #e4e9eb !important;
      box-shadow: none !important;
    }
  }
`;

export const Title = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Poppins';
  margin-bottom: 10px;
`;
export const FormInputPassword = styled(CommonInputPassword)`
  &.ant-input-affix-wrapper {
    padding: 10px 0px !important;
    font-family: 'Poppins' !important;
  }
  border-radius: none !important;
  background: transparent;
  margin-bottom: 10px;
  color: var(--text-main-color);
  background: transparent;
  border: none;
  border-bottom: 1px solid #e4e9eb !important;
  padding-bottom: 20px !important;

  &:is(:hover, :focus, :active) {
    border-color: white;
    border-bottom: 1px solid #e4e9eb !important;
    box-shadow: none !important;
  }
  & input.ant-input {
    background: transparent;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Text = styled.span`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`;

export const LinkText = styled(Text)`
  text-decoration: underline;
  color: var(--primary-color);
`;

export const SubmitButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  border-radius: 10px;
  background: var(--primery-color, #ee2150);
  border: none;
  color: #fff !important;
  height: 50px;
  margin-bottom: 10px;
  &:is(:hover, :focus, :active) {
    border: none;
    color: #fff !important;
  }
`;

export const SocialButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

export const FooterWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
`;

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`;

export const BackWrapper = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`;

export const SocialIconWrapper = styled.div`
  display: flex;
  margin-right: 0.8125rem;
  @media only screen and (${media.xs}) {
    margin-right: 0.625rem;
  }

  @media only screen and (${media.md}) {
    margin-right: 0.8125rem;
  }
`;
