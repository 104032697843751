import { Button } from 'antd';
import React from 'react';

const Analytics: React.FC = () => {
  return (
    <div>
      <Button>Analytics</Button>
    </div>
  );
};

export default Analytics;
