import Formdata from './Formdata';

const Formvalidation = () => {
  return (
    <>
      <Formdata />
    </>
  );
};

export default Formvalidation;
