import React from 'react';
import WebsiteLogo from '../ThemeControl/WebsiteLogo';

const InvoiceManage: React.FC = () => {
  return (
    <div>
      <WebsiteLogo />
    </div>
  );
};

export default InvoiceManage;
